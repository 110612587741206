@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  border: $main-white-full solid 2px;

  &:hover {
    border: $main-blue-full solid 2px;
  }
}

.gridContainer {
  flex-direction: column;
  height: 19rem;
}

.listContainer {
  flex-direction: row;
  height: 3.5rem;
}

.moreLabel {
  @include Swhitefullcenter;
}

.gridMoreLabel {
  margin-top: 1rem;
}
