@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.spaccer {
  margin-bottom: 0.5rem;
}

.fieldHeader {
  @include XSgrey_mediumleft;
}

.requiredLabel {
  padding-left: 0.5rem;
  @include XSnavyleft;
}

.mobileTitleSpaccer {
  margin-top: 1.5rem;
  margin-right: -0.5rem;
}
