.mobileTripleChoice {
  width: 100%;
}

.mobileTripleChoiceButton {
  width: 100% !important;
}

.mobileMargin {
  margin-right: -0.5rem;
}
