@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.firstTimePicker {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 10rem;
}

.secondTimePicker {
  display: flex;
  flex-grow: 10;
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 10rem;
}

.mobileFirstTimePicker {
  @extend .firstTimePicker;
  width: 9rem;
}

.mobileSecondTimePicker {
  @extend .secondTimePicker;
  width: 9rem;
}

.daysContainer {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.mobileDaysContainer {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.dayButton {
  min-width: 0;
  width: 2.75rem;
}

.mobileDayButton {
  min-width: 0;
  width: 2.5rem;
}

.removeButton {
  margin-top: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 1rem;

  height: 3.5rem;

  &:hover {
    cursor: pointer;
  }
}

.mobileRemoveButton {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem 0.5rem 0.3rem 0.5rem;
  border: 2px solid $main-blue-full;
  border-radius: 0.25rem;
  align-self: flex-end;
}

.removeButtonIcon {
  width: 1rem;
  height: 1rem;
}

.container {
  align-items: flex-start;
}

.mobileContainer {
  align-items: flex-start;
  background-color: $main-white-full;
  padding: 1.5rem 1rem 0 1rem;
  width: inherit;
}

.separator {
  height: 0.063rem;
  width: 100%;
  background-color: $contrast-grey-medium;
}
