.container {
  margin-top: 1rem;
  display: flex;
}

.containerDesktop {
  @extend .container;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.containerMobile {
  @extend .container;
  flex-direction: column;
}

.inputDesktop {
  width: 17rem;
}

.inputMobile {
  width: 100%;
  margin-bottom: 1rem;
}
