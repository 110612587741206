@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 95%;
}

.title {
  @include Lnavyleft;
}

.separator {
  height: 1px;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  background-color: $contrast-grey-medium;
}

.spaccer {
  margin-top: 2rem;
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.selectedCard {
  background-color: transparent;
  border-top: 1px solid $contrast-grey-medium;

  &:last-child {
    border-bottom: 1px solid $contrast-grey-medium;
  }
}

.selectedContainer {
  margin-top: 2rem;
}

.validationButton {
  margin-top: 2.5rem;
  width: 9rem;
}

.picker {
  margin-top: 1.5rem;
  width: 75%;
}

.mobilePicker {
  margin-top: 1.5rem;
}

.mobilePicker {
  margin-top: 1.5rem;
}

.errorMessage {
  @include XSerrorleft;
  margin-top: 0.25rem;
}
