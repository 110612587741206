@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.loader {
  margin-top: 10rem;
  margin-left: 10rem;
}

.mobileLoader {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 5rem;
}
