@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.completionMessage {
  width: 100%;
  margin-bottom: 2.5rem;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.messagesList {
  margin-left: 1rem;
}

.validationButton {
  margin-top: 2.5rem;
  width: 17rem;
}

.subtitle {
  margin-bottom: 2rem;
}

.textArea {
  width: 100%;
  min-height: 10rem;
}

.charCountLabel {
  @include XSnavyleft;
}

.errorMessage {
  @include XSerrorleft;
  margin-top: 0.25rem;
}

.clientPhotoRightsText {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.warningMessage {
  @include XSerrorleft;
}

.warningMessageSector {
  @include XSerrorleft;
  margin-top: -1.5rem;
  margin-bottom: 2rem;
}

.elementContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.sectorsContainer {
  margin-bottom: -2rem;
}

.expectedInformationSector {
  margin-bottom: -1.5rem;
}

hr {
  margin: 0 !important;
}
