@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.salaryContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.salaryFirstRowContainer {
  display: flex;
  flex-direction: row;
  gap: 3.5rem;
}

.mobileSalaryFirstRowContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.salarySecondRowContainer {
  display: flex;
  flex-direction: row;
  gap: 3.5rem;
}
