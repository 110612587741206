@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 11rem);
  margin-bottom: 2rem;
  grid-column-gap: 1rem;
  justify-content: space-between;
  grid-row-gap: 1rem;
}

.mobileGridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 9rem);
  margin-bottom: 2rem;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1rem;
}

.gridTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.subtitle {
  @include XSgrey_mediumleft;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}

.selectedNumber {
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  @include Snavyright;
}

.mobileSelectedNumber {
  @extend .selectedNumber;
  @include XSnavyright;
  white-space: nowrap;
}

.mobile {
  padding: 1px;
}

.mobileWidth {
  div {
    width: 10rem;
    min-width: 10rem;
    height: 11rem;
  }
}

.variableSizeButtons {
  grid-template-columns: repeat(3, 1fr);
}
