@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.validateButton {
  margin-top: 2.5rem;
  width: 18.5rem;
}

.subsection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2.5rem;
}

.mobileSubsection {
  @extend .subsection;
  flex-direction: column;
  gap: 1rem;
}

.mobileTripleChoice {
  width: 100%;
}

.label {
  flex: 1;
  justify-content: center;
}

.foldableContent {
  padding-top: 1rem;
  margin-bottom: 2.5rem;
}

.mobileFoldableContent {
  @extend .foldableContent;

  div {
    height: auto;
    padding-top: 0.5rem;
    padding-bottom: 0.2rem;
  }
}

.charCountLabel {
  @include XSnavyleft;
}

.sirOriginSelection {
  margin-top: 1rem;
}

.title {
  @include Lnavyleft;
}

.textArea {
  width: 100%;
  min-height: 10rem;
}

.errorMessage {
  @include XSerrorleft;
  margin-top: 0.25rem;
}
