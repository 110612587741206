@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.popover {
  width: 26rem;
  gap: 0;
  padding: 0.5rem;
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
  flex-shrink: 0;
}
