.completionMessage {
  width: 100%;
  margin-bottom: 2.5rem;
  align-items: center;
}

.messagesList {
  margin-left: 1rem;
  align-items: center;
}
