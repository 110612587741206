@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.selectedContainer {
  margin-top: 1rem;
}
.title {
  @include Lnavyleft;
  margin-top: 0.625rem;
  margin-bottom: 1.5rem;
}

.medicalSpecializationsPicker {
  margin-bottom: 1.5rem;
}

.separator {
  height: 1px;
  background-color: $contrast-grey-light;
}

.selectedCard {
  background-color: $contrast-grey-light;
}

.leftIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selectable {
  label {
    cursor: pointer;
  }
}

.diplomaIcon {
  margin-left: 0.5rem;
}
