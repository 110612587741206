@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.lightTitle {
  margin-top: 1.5rem;
}

.separator {
  height: 0.063rem;
  margin-top: 4rem;
  margin-bottom: 1.5rem;
  background-color: $contrast-grey-medium;
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.validateButton {
  margin-top: 2.5rem;
  width: 9.75rem;
}

.errorMessage {
  @include XSerrorleft;
  margin-top: 0.25rem;
}
