@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.warningStatus {
  border: solid 2px $system-danger !important;
  padding: 1rem !important;

  &:hover {
    border: solid 2px $system-danger !important;
    padding: 1rem !important;
  }

  &:focus-within {
    border: solid 2px $system-danger !important;
    padding: 1rem !important;
  }
}

.warningMessage {
  margin-top: 0.25rem;
  @include XSerrorleft;
}
