.voltageButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 8rem;
  width: 17rem;
  padding: 1rem;

  &:not(.disabled) {
    &:hover {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: -1px;
      margin-bottom: -1px;
      height: calc(8rem + 2px);
      padding: 1rem;
    }
  }
}

.mobileVoltageButton {
  @extend .voltageButton;
  width: 9.5rem;
  height: 8rem;
}

.voltageSelection {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 2rem;
  grid-column-gap: 1rem;
}

.dropDowns {
  margin-top: 1.125rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.mobileDropDowns {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
}

.dropDown {
  width: 17rem;
}

.mobileDropDown {
  width: 20.5rem;
}

.mobileDropDownChildren {
  max-height: 11rem;
  overflow: auto;
}

.mobileDropDownChildrenTwo {
  max-height: 5rem;
  overflow: auto;
}
