@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';
@use 'src/Utils/variables.scss';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: min(100%, variables.$app-max-width);
  align-self: center;
}

.illustration {
  @include ui.Paragraph;
  color: ui.$text-body-idle;
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
}

.content {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
