@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.regionHeader {
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding-right: 0.25rem;
  border-top: 1px solid ui.$border-feedback-disable-medium;
  gap: 1rem;
  cursor: pointer;
  &:hover {
    background-color: ui.$surface-action-tertiary-basic-over;
  }
}

.regionTitle {
  flex: 1 1;
}

.zoneTitle {
  margin-top: 0.25rem;
  padding-left: 1rem;
}

.zone {
  padding: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
}

.agencyTitle {
  @include ui.Legend;
}
