@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.validationButton {
  margin-top: 2.5rem;
  margin-bottom: 8rem;
  width: 9.25rem;
}

.picker {
  margin-top: 1.5rem;
  font-size: 0.75rem;
}

.generalPicker {
  margin-top: 2rem;
}

.separator {
  @extend %separator;
}

.rightSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include XSnavyleft;
  margin-right: 0.5rem;
}

.cacesCard {
  div {
    max-width: calc(100% - 3rem);
  }

  padding-left: 4rem;
}

.mobileCacesCard {
  @extend .cacesCard;
  height: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.selectedCard {
  background-color: transparent;
  border-top: 1px solid $contrast-grey-medium;

  &:last-child {
    border-bottom: 1px solid $contrast-grey-medium;
  }
}

.selectedContainer {
  margin-top: 2rem;
}

.errorMessage {
  @include XSerrorleft;
  margin-top: 0.25rem;
}
