@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.foldableListHeader {
  border-top: 1px solid $contrast-grey-medium;
  border-bottom: 1px solid $contrast-grey-medium;
}

.foldableListItem {
  border-top-width: 0;
  border-bottom: 1px solid $contrast-grey-medium;
  padding-left: 4rem;
}

.selectedCountLabel {
  @include Snavyleft;
}
