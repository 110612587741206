@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  max-width: 16rem;
}

.titleContainer {
  display: flex;
  flex: 0;
  flex-direction: column;
  padding: 1.5rem 0 1rem 0;
}

.linkContainer {
  display: flex;
  flex: 0;
  flex-direction: column;
}

.name {
  @include Snavyleft;
}

.title {
  @include XSgrey_mediumleft;
  margin-bottom: 0.5rem;
}

.link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  @include Sblueleft;
  width: 16rem;
  border-bottom: 1px solid $contrast-grey-medium;
  cursor: pointer;

  &.active {
    @include Snavyleft;
  }
}

.navNumber {
  width: 1.5rem;
}

.textAndBadgeWrapper {
  margin-right: 0.5rem;
}

.badgeWrapper {
  width: 50px;
  display: flex;
  justify-content: flex-end;
}

.dot {
  height: 1rem;
  width: 1rem;
  margin-left: 1rem;
  text-align: center;
  padding: 0.095rem 0rem 0rem 0rem;
  background-color: $system-danger;
  @include XSwhitefullcenter;
}

.firstDotMargin {
  margin-bottom: 1rem;
}

.textWrapper {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.noTopMargin {
  margin-top: 0;
}
