@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  background-color: $main-white-full;
  border-radius: 8px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
  height: 5.75rem;
  width: 100%;
}
