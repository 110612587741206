@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  padding: 1.75rem;

  height: 11rem;
  border: 1px solid $contrast-grey-strong;
  border-radius: 4px;
}

.riskCard {
  height: 11rem;
}
