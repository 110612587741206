.otherBonus {
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1rem;

  &:not(.disabled) {
    &:hover {
      height: calc(4rem + 2px);
      margin: -1px;
      width: calc(100% + 2px);
      padding: 1rem;
    }
  }

  svg {
    position: relative;
    left: -1rem;
    margin-top: 1rem;
    height: 2rem;
    width: 2rem;
  }
}

.otherBonusDesktop {
  @extend .otherBonus;

  div + div {
    position: relative;
    left: -2.5rem;
  }
}

.otherBonusMobile {
  @extend .otherBonus;

  div + div {
    position: relative;
    left: -1.5rem;
  }
}
