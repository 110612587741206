@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  @include Lnavyleft;
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  :nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.mobileToggleContainer {
  @extend .toggleContainer;
  gap: 0.5rem;
}

.subtitle {
  @include XSgrey_mediumleft;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}

.validateButton {
  margin-top: 2.5rem;
  width: 20rem;
}

.textArea {
  width: 100%;
  min-height: 10rem;
}

.characterAmount {
  @include XSnavyright;
}

.smallTopPadding {
  padding-top: 1rem;
}

.widthFitContent {
  width: fit-content;
}

.mobileSmallTopPadding {
  white-space: break-spaces;
}

.nameBlock {
  display: flex;
  flex-direction: row;
}

.withFlex {
  flex: 1;
}

.phoneNumber {
  width: 16rem;
}

.errorMessage {
  @include XSerrorleft;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.mobileContactContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.warningHomeFormalisation {
  border: solid 2px $system-danger !important;
  padding: 1rem !important;

  &:hover {
    border: solid 2px $system-danger !important;
    padding: 1rem !important;
  }

  &:focus-within {
    border: solid 2px $system-danger !important;
    padding: 1rem !important;
  }
}

.elementContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
