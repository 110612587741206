@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
}

.title {
  @include Lnavyleft;
  margin-bottom: 2rem;
}
.validateButton {
  margin-top: 2.5rem;
  width: 9.25rem;
}

.errorMessage {
  @include XSerrorleft;
  margin-top: 0.25rem;
}
