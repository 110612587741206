@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.bonusCardContainer {
  background-color: transparent;
  border-top: 1px solid $contrast-grey-medium;

  svg {
    height: 3rem;
    width: 3rem;
  }
}

.horizontalCardContainer {
  width: 100%;
  cursor: pointer;
}
