@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.icon {
  color: $main-white-full;
}

.trigger {
  width: 2.5rem;
  min-width: 0;
  border-width: 0;
  height: 2.5rem;
  border-radius: 8px;
  padding: 0.5rem;
}

.activeTrigger {
  background-color: $contrast-grey-light;
  circle {
    fill: $main-navy-full;
  }
}
