@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}
