@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;

  gap: 0.5rem;
}

.mobileContainer {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  width: 100%;
  gap: 0.5rem;
  justify-content: space-between;
}

.textInput {
  display: flex;
  width: 8.5rem;

  input {
    min-width: 0;
  }
}

.mobileTextInput {
  display: flex;
  width: 100%;

  input {
    min-width: 0;
    width: 100%;
  }
}
