.container {
  padding-top: 2rem;
}

.iconClassname {
  svg {
    width: 8rem;
    height: 3rem;
  }
}

.mobileHeight {
  height: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
