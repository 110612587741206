@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.completionMessage {
  width: 100%;
  margin-bottom: 2.5rem;
}

.messagesList {
  margin-left: 1rem;
}

.suggestedSkillsSection {
  margin-bottom: 2rem;
}

.mobileTitle {
  width: 11.75rem;
  white-space: pre-line;
}

.suggestedSkillsContainer {
  border-top: 1px solid $contrast-grey-medium;
  border-bottom: 1px solid $contrast-grey-medium;
}

.selectedSuggestionAmount {
  @include Snavyright;
}

.suggestedSkillCard {
  padding-left: 4rem;

  &:first-child {
    border-top-width: 0;
  }

  &:last-child {
    border-bottom: 1px solid $contrast-grey-medium;
  }
}

.selectedSkillsContainer {
  margin-top: 2rem;
  margin-bottom: 2.5rem;
}

.selectedSkillCard {
  border-top: 1px solid $contrast-grey-medium;

  &:last-child {
    border-bottom: 1px solid $contrast-grey-medium;
  }
}

.validationButton {
  margin-top: 2.5rem;
}

.mobileValidationButton {
  @extend .validationButton;
  max-width: 20.5rem;
}

.subtitle {
  @include Lnavyleft;
  margin-bottom: 1.5rem;
}

.taskTextArea {
  width: 100%;
  min-height: 10rem;
}

.tasksSubTitle {
  margin-top: 1.5rem;
}

.complementarySkillsSelection {
  width: 75%;
}

.mobileComplementarySkillsSelection {
  width: 100%;
}

.charCountLabel {
  @include XSnavyleft;
}

.errorMessage {
  @include XSerrorleft;
  margin-top: 0.25rem;
}

.warningMessage {
  margin-top: 0.25rem;
  @include XSerrorleft;
}
