@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.companyName {
  margin-bottom: 1rem;
  @include Snavyleft;
}

.mobileTitle {
  font-size: 1.5rem;
}
