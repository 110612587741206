@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.workingLocationTitle {
  margin-bottom: 1rem;
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.departmentContainer {
  width: 75%;
}

.cityContainer {
  margin-top: 1rem;
  width: 75%;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.elementContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.warningMessage {
  @include XSerrorleft;
}

.warningWorkLocation {
  border: solid 2px $system-danger;
  &:hover {
    border: solid 2px $system-danger;
    padding: 1px;
  }
  &:focus-within {
    border: solid 2px $system-danger;
    padding: 1px;
  }
}
