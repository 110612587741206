@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 4rem;
  border-top: 1px solid $contrast-grey-medium;

  &:hover {
    cursor: pointer;
    background-color: $contrast-grey-lighter;
  }
  padding-left: 1rem;
}

.selected {
  background-color: $contrast-grey-light;
}

.icon {
  margin-right: 0.5rem;
  svg {
    height: 3rem;
    width: 3rem;
  }
}

.identifier {
  @include Sblueleft;
}

.label {
  @include XSgrey_mediumleft;
  max-width: 75%;
}

.checkIcon {
  margin-left: auto;
  height: 1rem;
  width: 1rem;
  margin-right: 1rem;
}

.selectedText {
  @include Snavyleft;
}

.subLabelClassname {
  @include XSgrey_mediumleft;
  max-width: 22.375rem;
}
