.rightsActions {
  div {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 0.25rem;
  gap: 1rem;
}
