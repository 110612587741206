@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;
}

.mobileContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.smicBoxContainer {
  display: flex;
  flex-direction: column;
  height: 3.5rem;
  justify-content: center;
  margin-top: 1.25rem;
  margin-left: -0.5rem; // checbox has a 0.5 margin by default on the left

  margin-right: 2.5rem;
}
