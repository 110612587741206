@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.basicContainer {
  background-color: $main-white-full;
  border-radius: 8px;
  padding-left: 1.5rem;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.basicContainer:hover {
  border-color: $main-white-full;
  box-shadow: 0 0 0 2px $main-blue-full;
}

.container {
  @extend .basicContainer;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.mobileContainer {
  @extend .basicContainer;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.title {
  @include Snavyleft;
  margin-bottom: 0.5rem;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mobileTitle {
  @include Snavyleft;
  margin-bottom: 0.5rem;
  // Ellipsis on second line
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.description {
  @include XSgrey_mediumleft;
}

.completionStatus {
  @include XSgrey_mediumleft;
  margin-top: 0.25rem;
}

.completionStatusContainer {
  margin-top: 0.75rem;
  margin-right: 2.75rem;
  display: flex;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.rightSection {
  display: flex;
  align-items: center;
}
