@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button {
  height: 3rem;
  @include Sblueleft;
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;

  &:hover:not(:active),
  &:focus-visible {
    outline: none;
  }
}

.title {
  // Ellipsis on first line
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.leftArrow {
  margin-right: 0.25rem;
}

.separator {
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
  @include Snavyleft;
}
