@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  min-width: auto;
}

.mobileContainer {
  width: 9rem;
  height: 9rem;
  min-width: auto;
}

.mobileLabel {
  width: 9rem;
}
