@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.completionMessage {
  width: 100%;
  margin-bottom: 2.5rem;
}

.messagesList {
  margin-left: 1rem;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  :nth-child(1) {
    margin-bottom: 0 !important;
  }
}
.elementContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.title {
  @include Lnavyleft;
}

.separator {
  height: 1px;
  background-color: $contrast-grey-medium;
}

.warningDuration {
  border: solid 2px $system-danger;
  &:hover {
    border: solid 2px $system-danger;
    padding: 1px;
  }
  &:focus-within {
    border: solid 2px $system-danger;
    padding: 1px;
  }
}

.warningMessage {
  @include XSerrorleft;
}

.bonusContainer {
  display: flex;
  flex-direction: column;
  padding-right: 6rem;
  margin-top: 1rem;
}

.mobileBonusContainer {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.validateButton {
  margin-top: 2.5rem;
  width: 8.5rem;
}

.errorMessage {
  @include XSerrorleft;
  margin-top: 0.25rem;
}
