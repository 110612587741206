@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  padding: 1rem;
  padding-bottom: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}

.topBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0;
}

.topBarSpace {
  margin-bottom: 1.5rem;
}

.tabsContainer {
  flex: 0;
}

.rightIcon {
  padding-bottom: 1rem;
}

.tabContainer {
  flex: 1;
  flex-direction: row;
  display: flex;
}

.desktopTab {
  overflow-y: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tabContainer::-webkit-scrollbar {
  display: none;
}

.loader {
  margin-top: 10rem;
  margin-left: 10rem;
}

.mobileLoader {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 5rem;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
  margin-left: 2rem;
}

.errorYellowLadder {
  margin-bottom: 1rem;

  svg {
    width: 13.188rem;
    height: 16rem;
    object-fit: contain;
  }
}

.errorTitle {
  @include Lnavyleft;
  margin-bottom: 0.25rem;
}

.errorDescription {
  @include Mgreenishleft;
}

.mobileEDPName {
  @include XSnavyleft;
  margin-top: 1rem;
}
