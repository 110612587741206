@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.titleContainer {
  margin-bottom: 1rem;
}

.task {
  height: 4rem;
  border-bottom: 1px solid $contrast-grey-medium;
}

.listContainer {
  border-top: 1px solid $contrast-grey-medium;
}

.listTitleContainer {
  margin-top: 1rem;
}

.trashIcon {
  padding: 0.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  max-width: 2.5rem;
  margin-left: 0.475rem;
}
