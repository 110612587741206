@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.nameLine {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 1rem;
}

.nameColumn {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.title {
  margin-bottom: 1rem;
}

.input {
  width: 17rem;
}

.triggerButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3rem;
  @include Sblueleft;

  &:not(.disabled) {
    cursor: pointer;

    &:hover:not(:active),
    &:focus-visible {
      outline: none;
    }
  }
}

.pencilIcon {
  width: 1rem;
  height: 1rem;

  margin-right: 0.5rem;

  &.disabled {
    color: $contrast-grey-strong;
  }
}

.modalIcon {
  margin-top: -1.5rem;
}
