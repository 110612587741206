@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.mobile {
  width: 100%;
  justify-content: space-between;
  :last-child {
    margin-right: 0;
  }
}

.mobileButton {
  width: 100%;
}
