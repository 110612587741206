@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.completionMessage {
  width: 100%;
  margin-bottom: 2.5rem;
}

.messagesList {
  margin-left: 1rem;
}

.container {
  display: flex;
  flex-direction: column;
}

.title {
  @include Lnavyleft;
}

.subtitle {
  @include XSgrey_mediumleft;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}

.gridTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.selectedNumber {
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  @include Snavyright;
}

.validateButton {
  margin-top: 2.5rem;
  width: 8.75rem;
}

.textArea {
  width: 100%;
  min-height: 10rem;
}

.charCountLabel {
  @include XSnavyleft;
}

.warningContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.errorMessage {
  @include XSerrorleft;
}

.warningRisks {
  border: solid 2px $system-danger !important;
  padding: 1rem !important;

  &:hover {
    border: solid 2px $system-danger !important;
    padding: 1rem !important;
  }

  &:focus-within {
    border: solid 2px $system-danger !important;
    padding: 1rem !important;
  }
}
