@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';
@import './GridCard/GridCard.module.scss';
@import '../../../Utils/variables';

.greenBackground {
  padding-top: 1.5rem;
  background-color: $main-greenish-full;
  display: flex;
  justify-content: center;
  width: 100%;

  .displayContainer {
    max-width: $app-max-width;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 3rem;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;
  }

  .title {
    @include Lwhiteleft;
  }

  .studies {
    display: grid;
    column-gap: 1.5rem;
    row-gap: 0.75rem;
  }

  .grid {
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  }

  .formatIcon {
    height: 24px;
    width: 24px;
    margin-right: 0.5rem;
    path {
      fill: $main-white-full;
    }
  }
}

.noStudyImageMobile {
  transform: scale(0.35);
  translate: -19.5rem -8rem;
}
