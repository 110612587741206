@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  background-color: $main-white-full;
  border-radius: 8px;
  border: 1px solid $contrast-grey-medium;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
  min-height: 4.5rem;
  height: min-content;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mobileContainer {
  flex-direction: column;
}

.mobileActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 1rem;
  margin-top: -0.75rem;
  margin-right: -0.25rem;
}

.container:hover {
  border-color: $main-white-full;
  box-shadow: 0 0 0 2px $main-blue-full;
}

.dragIcon {
  margin-right: 1rem;
  margin-left: -0.5rem;
  margin-top: 0.75rem;
  min-width: 1.5rem;
}

.leftSide {
  display: flex;
  flex-direction: row;
  height: min-content;
}

.leftTextContainer {
  display: flex;
  flex-direction: column;
}

.description {
  @include XSgrey_mediumleft;
  margin-top: 0.5rem;
}

.leftIcon {
  margin-right: 1rem;
  height: 24px;
  width: 24px;
}

.title {
  @include Snavyleft;

  max-height: 5rem;
}

.rightsActions {
  div {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  flex-direction: row;
  height: min-content;
  margin-top: 0.25rem;
  gap: 1rem;
}
.leftIcon {
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  margin-right: 0.5rem;
}

.cardContainer {
  width: 100%;
  overflow: visible;
}

.openPopup {
  border-color: white;
  box-shadow: 0 0 0 2px $main-blue-full;
}

.seeAll {
  margin-left: 0.5rem;
  @include XSblueleft;
}

.listContainer {
  margin-top: 0.5rem;
  margin-left: 0.675rem;
  list-style-type: disc;
}
