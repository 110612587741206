@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.icon {
  min-width: 3rem;
  min-height: 3rem;
  color: $main-white-full;
}

.trigger {
  width: 2.5rem;
  min-width: 0;
  border-width: 0;
  height: 2.5rem;
  border-radius: 8px;
  padding: 1.5rem !important;
}

.activeTrigger {
  background-color: $contrast-grey-light;
  circle {
    fill: $main-navy-full;
  }
}

.link {
  text-decoration: none;
}

.loading {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
