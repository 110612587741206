@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  padding: 1.75rem;
  min-width: 14.5rem;
  height: 9.5rem;
  border: 1px solid $contrast-grey-strong;
  border-radius: 4px;
}

.warningSectors {
  border: solid 2px $system-danger !important;
  padding: 1rem !important;

  &:hover {
    border: solid 2px $system-danger !important;
    padding: 1rem !important;
  }

  &:focus-within {
    border: solid 2px $system-danger !important;
    padding: 1rem !important;
  }
}
