@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.illustration {
  @include ui.Paragraph;
  color: ui.$text-body-idle;
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
}

.container {
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
  flex-wrap: wrap;
}

.indicator {
  background-color: ui.$surface-feedback-brand-lighter;
  border-radius: 1rem;
  padding: 1rem;
  flex: 1 1 20rem;
  @include ui.Paragraph;
  color: ui.$text-body-idle;

  > :first-child {
    @include ui.Subheader;
  }

  .figure {
    @include ui.Titre_XXL;
    color: ui.$text-heading-link;
  }
}

.chartContainer {
  flex: 1 1 34rem;
}

.chartTitle {
  @include ui.Titre_XS;
  color: ui.$text-heading-idle;
  margin-bottom: 2rem;
}

.chart {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.responsiveContainer {
  flex: 1 1 15rem;
  aspect-ratio: 1;
  max-width: 28rem;
}

.missingInfo {
  color: ui.$surface-feedback-error-full;
  fill: currentColor;
}
.pendingUpdate {
  color: ui.$surface-feedback-brand-full;
  fill: currentColor;
}
.upToDate {
  color: ui.$surface-feedback-success-full;
  fill: currentColor;
}

.label {
  @include ui.Paragraph;
  fill: ui.$text-badge-label-label;
}

.legend {
  display: grid;
  grid-template-columns: auto 1fr;
  align-self: flex-start;
  max-width: 18rem;
  gap: 1rem;
  align-items: center;
  @include ui.Paragraph;
  color: ui.$text-body-idle;
}
