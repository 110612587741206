@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.statisticsContainer {
  width: 100%;
  height: 5.38rem;
  background-color: $main-greenish-lighter;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 0.5rem;
}

.dropDownContainer {
  margin-top: 0.75rem;
  margin-left: 1rem;
  margin-right: 0.5rem;
}

.dropDownTitle {
  @include Snavyleft;
  margin-bottom: 0.25rem;
}

.dropDown {
  background-color: $main-white-full;
  width: 15rem;
  height: 2.1rem;
  @include Sblueleft;
}

.progressBarContainer {
  padding: 0.5rem;
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  width: 100%;
  height: 2rem;
  margin-right: 0.5rem;
}

.detailsContainer {
  display: flex;
  justify-content: space-between;
}

.keysContainer {
  display: flex;
  margin-bottom: 0.25rem;
}

.chip {
  margin-right: 0.5rem;
}

.firstKey,
.secondKey {
  @include Sgrey_mediumleft;
  margin-right: 1rem;
  line-height: 1rem;
}

.thirdKey {
  @include Sgrey_mediumleft;
  line-height: 1rem;
}

.statusLabel {
  @include Snavyleft;
  margin-bottom: 0.25rem;
}

.progressBar {
  width: 100%;
  display: flex;
  height: 2rem;
  border-radius: 0.25rem;
  overflow: hidden;
}

.progressSegment {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  @include Mwhiteleft;
  cursor: pointer;
}

.upToDate {
  background-color: $system-sucess;
}

.pendingUpdate {
  background-color: $main-blue-full;
}

.missingInfo {
  background-color: $system-danger;
}
