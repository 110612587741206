@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.tableContainer {
  display: flex;
  flex-direction: column;
  gap: 0;
  min-height: calc(100vh - 7rem);
}

.illustration {
  @include ui.Paragraph;
  color: ui.$text-body-idle;
  display: flex;
  gap: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}

.emptyIllustration {
  @include ui.Paragraph;
  color: ui.$text-body-idle;
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.table tbody tr td {
  color: ui.$text-body-idle;
}

.completionStatus {
  display: flex;
  align-items: center;
}

.completionStatusIcon {
  margin-top: 0.2rem;
}
