@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  min-height: 4rem;
  background-color: $main-navy-full;
  display: flex;
  flex-direction: row;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;
}

.hideTopBar {
  margin-top: -4rem;
}

.topBarComponent {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 4.5rem;
}

.menuContainer {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.userInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1.25rem;
  height: 5rem;
  gap: 0.75rem;
}

.menuItem {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  padding-left: 1.25rem;
  height: 4.5rem;
}

.firstMenuItem {
  margin-top: 1rem;
}

.lastMenuItem {
  margin-bottom: 1rem;
}

.selectedMenuItem {
  background-color: $colors-alpha-white-lightest;
}

.whiteText {
  @include Swhitefullleft;
  margin-bottom: 0.25rem;
}

.greyText {
  @include XSgrey_mediumleft;
  width: 13rem;
}

.version {
  @include XSgrey_mediumleft;
  margin-left: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.circle {
  height: 2.5rem;
  width: 2.5rem;
  background-color: $contrast-grey-light;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
}

.gridIcon {
  path {
    fill: white;
  }
}

.lineSeparator {
  height: 1px;
  background-color: $contrast-grey-light;
}

:global(.bm-burger-button) {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  left: 1.5rem;
  top: 1.25rem;
  z-index: 19;
}

:global(.bm-menu-wrap) {
  width: 19.5rem !important;
}

:global(.bm-menu) {
  background-color: $main-navy-full;
}

:global(.bm-overlay) {
  background: rgba(0, 0, 0, 0.5) !important;
  z-index: 20;
}

.burgerIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.separator {
  width: 0.063rem;
  height: 1.688rem;
  background-color: $contrast-grey-medium;
  margin: 0 0.5rem;
}

.title {
  @include Lwhiteleft;
}
