@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';
@import-normalize "opinionated.css";

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-weight: normal;
}

body {
  margin: 0;
  font-family: 'Graphik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

:where(button) {
  background-color: transparent;
  border: none;
}

:where(button.active) {
  opacity: 0.7;
}

:where(button:disabled) {
  opacity: 1;
}

hr {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  border: none;
  height: 1px;
  background-color: $contrast-grey-medium;
}
