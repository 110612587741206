@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

$card-height: 8rem;

.container {
  background-color: $main-white-full;
  flex-direction: row;
  height: $card-height;
  border-radius: 8px;
  display: flex;
  cursor: pointer;
}

.container:hover {
  box-shadow: 0 0 0 2px $main-blue-full;
}

.description {
  @include XSgrey_mediumleft;
}

.firstPart {
  background-color: $main-white-full;
  padding: 1rem 0.75rem;
  height: $card-height;
  border-radius: 8px 0 0 8px;
  display: flex;
  width: 46%;
  flex-direction: column;

  .date {
    @include XSgrey_mediumleft;
  }

  .service {
    @include Mblueleft;
    margin-top: 0.5rem;
    // Ellipsis on first line
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .siret {
    @include XSgrey_mediumleft;
    // Ellipsis on one line
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .serviceAddress {
    @include XSgrey_mediumleft;
    // Ellipsis on one line
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.secondPart {
  background-color: $main-beige-full;
  padding: 1rem 0.75rem;
  height: $card-height;
  display: flex;
  width: 46%;
  flex-direction: column;
  justify-content: center;

  .qualification {
    @include Snavyleft;
    // Ellipsis on one line
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .name {
    @include Sgrey_mediumleft;
    margin-top: 0.5rem;
    // Ellipsis on one line
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.thirdPart {
  background-color: $main-beige-full;
  padding: 1rem 0.75rem;
  height: $card-height;
  border-radius: 0 8px 8px 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.completionStatus {
  @include XSgrey_mediumleft;
  line-height: 1rem;
}

.completionStatusContainer {
  margin-left: -0.25rem;
  display: flex;
  align-items: center;
}
