@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.select {
  height: 3.5em;
  background-color: ui.$main-white-full;
  border: 1px solid ui.$contrast-grey-medium;
  padding: 1px 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  flex-wrap: nowrap !important;

  &:hover,
  &.focused {
    padding: 0 calc(1rem - 1px);
    border: 2px solid ui.$main-blue-full;
    outline: none;
    box-shadow: none;
  }
  &.error {
    border: solid 2px ui.$system-danger;
    padding: 0 calc(1rem - 1px);
  }

  .valueContainer {
    @include ui.Paragraph;
    display: flex;
    gap: 0.5em;
  }

  .valueContainerChildren {
    align-items: center;
    display: grid;
    flex: 1;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
  }

  .placeholder {
    color: ui.$main-blue-full;
  }
  &.error .placeholder {
    color: ui.$system-danger;
  }
  &.disabled .placeholder {
    color: ui.$contrast-grey-strong;
  }

  .indicatorSeparator {
    display: none;
  }

  .indicatorContainer {
    margin: 0;
  }

  .arrow {
    transition: transform 200ms ease-out;
  }
  &.disabled {
    svg path {
      fill: ui.$contrast-grey-medium;
    }
  }
  &.open .arrow {
    transform: rotate(180deg);
  }
}

.crossIcon {
  margin-right: 0.5rem;
  path {
    fill: ui.$main-blue-full;
  }
}

.portal {
  z-index: 1500 !important;
}

.menu {
  margin-top: 0;
  border-radius: 0.5rem;
  border: none;
  box-shadow: 0 1px 5px 0 ui.$contrast-grey-medium;
}

.menuList {
  padding: 0.25rem;
}

.noOptionsMessage {
  line-height: 1.25;
  padding: 0.5rem 1rem 0.5rem 0.75rem;
  color: ui.$contrast-grey-strong;
}
