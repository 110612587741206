@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.arrowRight {
  width: 1rem;
  height: 1rem;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  background-color: $contrast-grey-light;
  align-items: center;
  justify-content: space-between;
  padding-left: 9.5rem;
  height: 4rem;
  border-top: 1px solid $main-white-full;
  border-bottom: 1px solid $main-white-full;

  &:hover {
    cursor: pointer;
    background-color: $contrast-grey-lighter;
  }
}

.textRight {
  padding-right: 1rem;
}

.triggerLabel {
  color: $main-blue-full;
}

.mobileTriggerLabel {
  @extend .triggerLabel;
  font-size: 0.75rem;
}

.rightCardComponent {
  padding-right: 1rem;
  display: flex;
  align-items: center;
  @include XSnavyleft;
}

.mobileSelectedNumber {
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 0.5rem;
  margin-left: -1.5rem;
  @include XSnavyleft;
}

.cardLabel {
  :nth-child(2) {
    max-width: 28.5rem;
  }
}

.mobileCardLabel {
  @extend .cardLabel;
  height: 7rem;
}
