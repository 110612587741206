@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.mandatory {
  background-color: $main-warmred-full;
}

.wished {
  background-color: $system-info;
}

.listContainer {
  margin-right: 2px;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.validationButton {
  margin-top: 2.5rem;
  margin-bottom: 8rem;
  width: 22.25rem;
}

.mobileValidationButton {
  width: 100%;
  height: 3.5rem;
}

.errorMessage {
  @include XSerrorleft;
  margin-top: 0.25rem;
}

.noSkills {
  @include Snavyleft;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.separator {
  height: 1px;
  background-color: $contrast-grey-medium;
}

.cadenceTitle,
.cadenceDescriptionContainer,
.attendantProductivityContainer {
  margin-top: 1.5rem;
}

.textArea {
  width: 100%;
  min-height: 10rem;
}

.explanationTimeContainer,
.learningTimeContainer {
  margin-top: 1.5rem;
  width: 15rem;
  margin-right: 1rem;
}

.mobileLearningTimeContainer {
  width: 10rem;
}

.explanationTimeAndUnitContainer,
.learningTimeAndUnitContainer {
  display: flex;
}

.mobileExplanationContainer {
  flex-direction: column;
}

.timeUnitContainer {
  margin-top: 1.5rem;
}

.mobiletimeUnitContainer {
  margin-top: 0.5rem;
}

.charCountLabel {
  @include XSnavyleft;
}

.workerProductivityContainer {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.informationIcon {
  line-height: 1rem;
  margin-right: 5rem;
  &:hover {
    cursor: pointer;
  }
}
