@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';
.selectedCard {
  background-color: transparent;
  border-top: 1px solid $contrast-grey-medium;

  &:last-child {
    border-bottom: 1px solid $contrast-grey-medium;
  }
}

.selectedContainer {
  margin-top: 2rem;
}

.validationButton {
  margin-top: 2.5rem;
  width: 11.75rem;
}

.picker {
  margin-top: 1.5rem;
}

.errorMessage {
  @include XSerrorleft;
  margin-top: 0.25rem;
}
