@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

$upper-height: 11rem;
$lower-height: 8rem;
$card-height: calc(#{$lower-height} + #{$upper-height});
$column-gap: 1rem;

.container {
  background-color: $main-white-full;
  flex-direction: column;
  height: $card-height;
  border-radius: 8px;
  cursor: pointer;
}

.container:hover {
  box-shadow: 0 0 0 2px $main-blue-full;
}

.description {
  @include XSgrey_mediumleft;
}

.upperPart {
  background-color: $main-white-full;
  padding: 1rem 0.75rem;
  height: $upper-height;
  border-radius: 8px 8px 0 0;

  .firstRow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 1.75rem;
    justify-content: space-between;
    align-items: center;
  }

  .date {
    @include XSgrey_mediumleft;
  }

  .service {
    @include Mblueleft;
    margin-top: -0.125rem;
    margin-bottom: 0.375rem;
    display: flex;
    align-items: center;
    height: 3rem;

    .serviceText {
      // Ellipsis on second line
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .siret {
    @include XSgrey_mediumleft;
    // Ellipsis on one line
    display: -webkit-box;
    margin-top: 0.25rem;
    margin-bottom: -0.25rem;
    height: 1rem;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .serviceAddress {
    @include XSgrey_mediumleft;
    margin-top: 0.5rem;
    margin-bottom: -0.25rem;
    // Ellipsis on second line
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.lowerPart {
  background-color: $main-beige-full;
  padding: 1rem 0.75rem;
  height: $lower-height;
  border-radius: 0 0 8px 8px;

  .qualification {
    @include Snavyleft;
    height: 2.5rem;
    // Ellipsis on second line
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .name {
    @include Sgrey_mediumleft;
    margin-top: 0.5rem;
    // Ellipsis on second line
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.completionStatus {
  @include XSgrey_mediumleft;
}

.completionStatusContainer {
  margin-top: 1rem;
  margin-left: -0.25rem;
  display: flex;
  align-items: center;
}

.completionStatusIcon {
  margin-top: 0.2rem;
}
