@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.numberOfPositionStudies {
  @include Snavyleft;
  margin-bottom: 1rem;
}

.displayMore {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
}
