@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';
@import '../../Utils/variables';

.container {
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}

.topContainer {
  max-width: $app-max-width;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
}

.icon {
  position: absolute;
  bottom: -1rem;
  right: -2rem;
}

.mobileIcon {
  position: absolute;
  top: 4rem;
  right: -1rem;

  max-width: 10rem;
  max-height: 5.875rem;
}

.searchContainer {
  z-index: 30;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media (min-width: 770px) {
  .searchContainer {
    width: 50%;
  }
}

.title {
  @include XLnavyleft;
  margin-bottom: 1rem;
  background-color: $main-beige-full;
}

.mobileTitle {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 2.5rem;
  background-color: $main-beige-full;
}

.companyPicker {
  max-width: 40rem;
}

.hello {
  @include Mblueleft;
}

.separator {
  display: none;
}

.positionStudiesCounter {
  @include Swhitefullcenter;
  margin-bottom: 0.5rem;
  width: 1.8rem;
}

.counterTens {
  width: 2.5rem;
}

.counterHundreds {
  width: 2.8rem;
}

.selectedAll {
  background-color: $main-navy-full;
}

.titles {
  margin-bottom: 0.5rem !important;
}

.menu {
  margin-top: 0.5rem;
  margin-bottom: -2rem;
}

.leftBottomBar {
  width: 9rem !important;
}

.rightBottomBar {
  width: 11.75rem !important;
  margin-left: 0.75rem !important;
}

.rightBottomBarHundreds {
  width: 12.1rem !important;
  margin-left: 0.75rem !important;
}

.verticalSeparator {
  margin-bottom: 0.5rem;
}
