@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow-y: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}

.goBack {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 1.75rem;
  @include Sblueleft;

  &:hover {
    @include Snavyleft;
    circle {
      fill: $main-navy-full;
    }
  }
}

.goBackArrowButton {
  color: $main-white-full;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;

  svg {
    width: 3rem;
    height: 3rem;
  }
}

.goBackArrowButtonMobile {
  margin-right: 0.25rem;
  display: flex;
}

.titleBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.mobileTitleBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.mobileTextSize {
  font-size: 0.95rem;
}

.companyNameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4.25rem;
}

.mobileCompanyName {
  @include XLnavyleft;
  text-align: center;
  margin-bottom: 2rem;
}

.companyName {
  @include XLnavyleft;
}
