@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.mobileEDPName {
  @include XSnavyleft;
  margin-top: 1rem;
}

.mobileTabsContainer {
  flex: 0;
  display: flex;
  flex-direction: column;
}

.mobileTabsSection {
  flex: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mobileTitle {
  font-size: 1.325rem;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.mobileNavigationArrows {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0;
  margin-left: auto;
  gap: 1rem;
}

.mobileArrow {
  border: 0 !important;
  padding: -2rem;
  margin: 0;
}

.mobileSubSections {
  font-size: 1.1rem;
}

.subTabContainer {
  white-space: nowrap;
}

.mobilePreview {
  margin-left: -2rem;
}

.disabled {
  opacity: 0.5;
}

.horizontalSeparator {
  @extend %separator;
  margin-left: -1rem;
  margin-right: -1rem;
}

.dot {
  height: 1rem;
  width: 1rem;
  text-align: center;
  padding: 2px 0px 0px 0px;
  background-color: $system-danger;
  @include XSwhitefullcenter;
  margin-left: 0.5rem;
}
