@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.icon {
  margin-top: -1.5rem;
}

.loadingText {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.text {
  @include Snavyleft;
}

.loading {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
