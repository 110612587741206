@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.completionMessage {
  width: 100%;
  margin-bottom: 2.5rem;
}

.messagesList {
  margin-left: 1rem;
}

.container {
  display: flex;
  flex-direction: column;
}

.title {
  @include Lnavyleft;
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  align-items: center;
  :nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.subtitle {
  @include XSgrey_mediumleft;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}

.validateButton {
  margin-top: 2.5rem;
  width: 18.5rem;
}

.otherRisksContainer {
  div {
    background-color: $main-white-full;
  }

  textarea {
    width: 100%;
    min-height: 10rem;
  }
}

.charCountLabel {
  @include XSnavyleft;
}

.label {
  flex: 1;
  justify-content: center;
}

.subsection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2.5rem;
}

.mobileSubsection {
  @extend .subsection;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}

.mobileTripleChoice {
  width: 100%;
}

.warningEps {
  border: solid 2px $system-danger !important;
  padding: 1rem !important;

  &:hover {
    border: solid 2px $system-danger !important;
    padding: 1rem !important;
  }

  &:focus-within {
    border: solid 2px $system-danger !important;
    padding: 1rem !important;
  }
}

.errorMessage {
  @include XSerrorleft;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 0.5rem;
}

.tripleChoice {
  justify-content: flex-end;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
